import React from 'react';
import PropTypes from "prop-types";
import RichText from '../richText';



const Results = ({ data }) => {

      return (

            <>

                  <div className="c-case-study__results c-case-results c-section js-header-waypoint" data-logo="light" data-nav="light">
                        <div className="c-case-results__decoration" />
                        <div className="c-case-results__wrapper o-wrapper">
                              <div className="u-content u-serif">
                                    {data.primary.title1 && data.primary.title1[0] &&  <h3>{data.primary.title1[0].text}</h3>}
                                   
                                    {data.primary.content &&  <div className="u-justify">
                                          <RichText render={data.primary.content} />
                                    </div>
                                    }
                              </div>


                              {data.fields &&
                                    <ul className="c-case-results__numbers">

                                          {data.fields.map((field, i) => {

                                                return (

                                                      <li key={i} className="c-case-results__item">

                                                            <p>
                                                                  {field.value && field.value[0] && <strong className="c-case-results__num">{field.value[0].text}</strong>}
                                                                  {field.description1 && field.description1[0] && <span className="c-case-results__text u-serif">{field.description1[0].text}</span>}
                                                            </p>

                                                      </li>

                                                )
                                          })}


                                    </ul>
                              }
                        </div>
                  </div>

            </>


      );
}

Results.propTypes = {
      data: PropTypes.object
}

export default Results;