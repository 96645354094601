import React from 'react';
import PropTypes from "prop-types";


const Testimony = ({ data }) => {

      return (

            <>

                  <div className="c-case-testimonial c-section o-wrapper js-header-waypoint" data-logo="dark" data-nav="dark">
                        <h3 className="u-hidden-for-seo">{data.author[0].text}</h3>
                        <div className="c-case-testimonial__holder">
                              <blockquote className="c-case-testimonial__text u-serif u-justify">
                                    <span className="c-case-testimonial__icon" />
                                    {data.content[0].text}
        </blockquote>
                              <strong className="c-case-testimonial__name">{data.author[0].text}</strong>
                        </div>
                  </div>

            </>


      );
}

Testimony.propTypes = {
      data: PropTypes.object
}

export default Testimony;