import React from 'react';
import PropTypes from "prop-types";
import RichText from '../richText';


import Img from "gatsby-image"

const FullScreenHero = ({ data }) => {

      const $mainInfo = data.body[0];

      return (

            <>
                  <div className="c-fullscreen-hero">

                        <div className="c-fullscreen-hero__top o-align-middle js-header-waypoint" data-logo="light" data-nav="light">
                              <div className="c-fullscreen-hero__aligner o-align-middle__aligner">
                                    <div className="o-wrapper">
                                          {data.client_image &&
                                                <div className="c-fullscreen-hero__logo">
                                                      <img src={data.client_image.url} width={150} alt={data.client_name[0].text} />
                                                </div>
                                          }
                                          <h2 className="c-fullscreen-hero__title c-heading-group__title">{data.title[0].text}</h2>
                                          {data.description &&  data.client_image && <p className="c-fullscreen-hero__subtitle">{data.client_name[0].text} {data.description[0].text}</p>}
                                    </div>
                              </div>
                              {data.image_hero &&
                                    
                                          <Img className="c-fullscreen-hero__image u-img" fixed={data.image_heroSharp.childImageSharp.fluid} />
                                    
                              }
                        </div>
                        <div className="c-fullscreen-hero__waypoint js-header-waypoint" data-logo="dark" data-nav="dark" />


                        <div className="o-wrapper">
                              <div className="c-fullscreen-hero__box c-main-info">

                                    <div className="c-main-info__row">

                                          {$mainInfo.fields.map((field, i) => {

                                                return (


                                                      <div key={i} className="c-main-info__col">
                                                            <h3 className="c-main-info__title">{field.title1[0].text}</h3>
                                                            <div className="c-main-info__content u-serif  u-justify">
                                                                  <RichText render={field.content} />
                                                            </div>
                                                      </div>


                                                )
                                          })}

                                    </div>
                              </div>
                        </div>
                  </div>
            </>


      );
}

FullScreenHero.propTypes = {
      data: PropTypes.object
}

export default FullScreenHero;