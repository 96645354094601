import React from 'react';
import PropTypes from "prop-types";
import RichText from '../richText';



const MainData = ({ data }) => {


      return (

            <>


                  <div className="c-case-study__wrapper o-wrapper">
                        <div className="c-case-study__main-data u-content u-serif">
                              <div className="c-case-study__row">

                                    {data.fields.map((field, i) => {

                                          return (

                                                <div key={i} className="c-case-study__col c-section--t">
                                                      <h3>{field.title1[0].text}</h3>
                                                      <span className="u-justify">
                                                         <RichText render={field.content} />
                                                      </span>
                                                </div>

                                          )
                                    })}


                              </div>
                        </div>
                  </div>


            </>


      );
}

MainData.propTypes = {
      data: PropTypes.object
}

export default MainData;